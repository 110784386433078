import React from "react";
import styles from "../../Home/HomePage.module.css";
const TableComponent = (props) => {

  console.log("latest props.watchlist", props.watchlist);
  return (
    <div>
      <table className={`${styles.tableUser} mt-3`}>
        <thead>
        <tr>
          <th colSpan={2} className="font-montserrat">
            Current Holdings
            <div className="flex flex-row text-xs font-normal font-poppins items-center text-[#ffffff]/60 italic mt-2 lowercase">
              (
              <div className="h-2 w-2 rounded-full bg-[#18B13F] mr-2 ml-2"></div>
              indicates this week's new addition to the portfolio )
            </div>
            <div className="flex flex-row text-xs font-normal font-poppins items-center text-[#ffffff]/60 italic mt-2 lowercase">
              (
              <div className="h-2 w-2 rounded-full bg-[#f43f5e] mr-2 ml-2"></div>
              indicates removal / exit from the portfolio )
            </div>
          </th>
        </tr>
        <tr>
          <th className="font-poppins w-full ">Stocks</th>
          <th className="font-poppins w-full max-w-[100px]">Allocation</th>
        
        </tr>
        </thead>
        <tbody>
          {props.watchlist &&
            props.watchlist
              .sort((a, b) => a.id - b.id)
              .map((ele, i) => {
                return (
                  <tr key={i}>
                   <td className="">
                      <div className="text-[14px] flex  items-center font-poppins">

        
                      {ele[1] === "In" ? (
                        <span className="h-3 w-3 rounded-full bg-[#18B13F] mr-4"></span>
                      ) : ele[1] === "Out" ? (
                        <span className="h-3 w-3 rounded-full bg-[#f43f5e] mr-4"></span>) : null}

                         {ele[0]}
                        </div>
                    </td>
                    <td className="max-w-[100px] text-right font-poppins">
                    {ele[2]}
                    </td>
                    
                    
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;















// import React from "react";
// import styles from "../../Home/HomePage.module.css";
// const TableComponent = (props) => {
//   return (
//     <div>
//       <table className={`${styles.tableUser} mt-3`}>
//         <thead>
//           <tr>
//             <th scope="col" className="font-montserrat">
//               Current Holdings
//               <div className="flex flex-row text-xs font-normal font-poppins items-center text-[#ffffff]/60 italic mt-2 lowercase">
//                 (
//                 <div className="h-2 w-2 rounded-full bg-[#18B13F] mr-2 ml-2"></div>
//                 indicates this weeks new addition to the portfolio )
//               </div>
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {props.watchlist &&
//             props.watchlist
//               .sort((a, b) => a.id - b.id)
//               .map((ele, i) => {
//                 return (
//                   <tr key={i}>
//                     <td className="flex flex-row items-center font-poppins">
//                       {ele[3] === "buy" ? (
//                         <span className="h-3 w-3 rounded-full bg-[#18B13F] mr-3"></span>
//                       ) : null}

//                       {ele[4].replace("Ord Shs", "")}
//                     </td>
//                   </tr>
//                 );
//               })}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default TableComponent;

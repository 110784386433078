import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Navbar from "../Navbar/Navbar";
import styles from "../../Home/HomePage.module.css";
import TableComponent from "./TableComponent";
import { auth } from "../../firebase";
import {
  CurrentUserData,
  UseTradeHistoryData,
  UseOverviewData,
  UseOverviewDataHeading,
  UseYearOnYearData,
  UseAlphaOptionsData,
} from "../../utils/data";
import DashBoard from "../../Home/DashBoard";
import OutPerformaceAndChart from "./OutPerformaceAndChartt";

const AlphaIndiaOptions = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const { data: userHero } = CurrentUserData(userEmail);
  const userDetails = userHero && userHero.data.User;

  const { data: tradeHistory } = UseTradeHistoryData();

  const { data: overviewData } = UseOverviewData();
  const { data: overviewDataHeading } = UseOverviewDataHeading();
  const { data: yearOnYearData } = UseYearOnYearData();

  const newOverViewDataHeading =
    overviewDataHeading && overviewDataHeading.data.values;
  const newOverViewData = overviewData && overviewData.data.values;
  const newYearOnYearData = yearOnYearData && yearOnYearData.data.values;

  const newData1 = tradeHistory && tradeHistory.data.mappings;
  const result =
    newData1 &&
    newData1.filter(
      (item) => item.frontend_table_name === "Alpha 500 (20 Selections)"
    );
  const smallCapDataTablename = result && result[0]["table_name"];

  const smallCapData =
    tradeHistory && tradeHistory.data.data[smallCapDataTablename];

  // chart  data

  const { data: alphaOptData } = UseAlphaOptionsData();

  // alpha 500 start
  const alphaOptionsData = alphaOptData && alphaOptData.data.values;
  const alphaOptionsPerformceAccountValue =
  alphaOptionsData && alphaOptionsData.map((item) => [item[0], item[1], item[2]]);
  const alphaOptionsOutPerformance =
  alphaOptionsData && alphaOptionsData.map((item) => [item[10]]);

  const alphaOptionsOutPerformanceValue =
  alphaOptionsOutPerformance &&
  alphaOptionsOutPerformance.flat().filter((value) => value !== undefined && value !== "");

  const alphaOptionsPerformceAccountValueData =
  alphaOptionsPerformceAccountValue && [
      ["Date", "Alpha Options", "Nifty 50"],
      ...alphaOptionsPerformceAccountValue
        .filter(
          (innerArray) =>
            innerArray &&
            innerArray.every((value) => value !== undefined && value !== "")
        )
        .map((innerArray, index) =>
          innerArray.map((value, columnIndex) => {
            // Skip the first column
            if (columnIndex === 0 || typeof value !== "string") {
              return value;
            }

            // Remove the leading rupee sign and commas, then convert to a number
            return parseFloat(value.replace(/^₹|,/g, ""));
          })
        ),
    ];

  const newAlphaOptionsData =
  alphaOptionsData && alphaOptionsData.map((item) => [item[0], item[5], item[6]]);
  const dataArray = newAlphaOptionsData ? newAlphaOptionsData : [];

  const alphaOptionsMonthlyReturns = [dataArray[0]]; // Add the header to the new array

  // Create a Set to keep track of unique months
  const uniqueMonthsSet = new Set();

  // Iterate through the original array starting from index 1 (skipping the header)
  for (let i = 1; i < dataArray.length; i++) {
    const currentRow = dataArray[i];
    const currentDate = currentRow[0];

    // Check if currentDate is defined and is a valid string
    if (currentDate && typeof currentDate === "string") {
      // Convert the date string to a JS Date object
      const [day, month, year] = currentDate.split("/").map(Number);
      const currentMonthKey = `${month}/${year}`; // Extract the month and year part

      // Check if the current row has valid data (non-empty and non-undefined values)
      const hasValidData = currentRow
        .slice(1)
        .every((value) => value !== undefined && value !== "");

      if (!uniqueMonthsSet.has(currentMonthKey) && hasValidData) {
        alphaOptionsMonthlyReturns.push(currentRow);
        uniqueMonthsSet.add(currentMonthKey);
      }
    }
  }

  const alphaOtionsCumulative = alphaOptionsData && alphaOptionsData[1][9];
  const niftyCumulative = alphaOptionsData && alphaOptionsData[5][9];



  return (
    <div>
      {userDetails && userDetails.account_access === "admin" ? (
        <DashBoard />
      ) : (
        <div>
          <Navbar />

          <div className="mt-20">
            <div className="bg-[#14141C] flex flex-row py-8 px-12">
              <div className="w-[50%] px-8">
                <div className="text-2xl text-white font-montserrat">
                  Alpha India Options
                </div>
                <div className="text-lg text-[#ffffff]/60 mt-2 font-poppins">
                  Unleashing the Power of Momentum Investing
                </div>
              </div>
              <div className="border-l-[1px] border-[#ffffff]/30 px-8">
                <div className="text-lg text-white font-montserrat font-semibold">
                  Investment Objective
                </div>
                <div className="text-base text-[#ffffff]/60 mt-2 font-poppins">
                  Alpha Options is a concentrated momentum portfolio designed to
                  tackle these challenges head-on.Our quantitative strategy
                  scans the top 350 small caps in the Nifty index and selects a
                  portfolio of 10 promising stocks for your investment
                  consideration. What sets this strategy apart is its weekly
                  rebalancing, ensuring that underperformers are promptly
                  replaced while keeping your portfolio aligned with the
                  ever-evolving market dynamics.
                </div>
              </div>
            </div>
            <div className={`px-4 pt-4 pb-16 flex flex-row justify-between`}>
              {/* <div className="w-[30%] pl-4">
               

                {newAlpha500Indexdata && newAlpha500Indexdata ? (
                  <TableComponent watchlist={newAlpha500Indexdata && newAlpha500Indexdata} />
                ) : null}
                <div className="w-full">
                  <div className="font-montserrat border-[1px] border-b-0 border-[#ffffff]/20 bg-[#14141C]  w-[90%] text-white mt-3 py-[10px] pl-3 font-semibold">
                    Statistics
                  </div>

                  <table className={`${styles.tableUser2}`}>
                    <thead>
                      {newOverViewDataHeading &&
                        newOverViewDataHeading.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <th scope="col"></th>
                              <th scope="col">{ele[7]}</th>
                              <th scope="col">{ele[2]}</th>
                              <th scope="col">{ele[8]}</th>
                            </tr>
                          );
                        })}
                    </thead>

                    <tbody>
                      {newOverViewData &&
                        newOverViewData.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td>{ele[0]}</td>
                              <td className="text-right">{ele[7]}</td>
                              <td className="text-right">{ele[2]}</td>
                              <td className="text-right">{ele[8]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div> */}
              <div className="w-[70%] mx-auto">
                <OutPerformaceAndChart
                  monthlyReturns={
                    alphaOptionsMonthlyReturns && alphaOptionsMonthlyReturns
                  }
                  performaceData={
                    alphaOptionsPerformceAccountValueData &&
                    alphaOptionsPerformceAccountValueData
                  }
                  outPerformance={alphaOptionsOutPerformanceValue}
                  titleOne="Alpha Options"
                  titleTwo="Nifty 50"
                  tabName="alpha-options"
                  alphaCumulative={alphaOtionsCumulative}
                  niftyCumulative={niftyCumulative}
                />
                <div className="w-full text-white text-2xl font-bold py-4 pl-3  font-montserrat">
                  Backtested Year-on-Year Performance
                </div>

                <div className="w-full text-white text-[16px] font-normal py-4 pl-3  font-montserrat">
                    Coming Soon ...
                </div>

                {/* <table className={`${styles.tableUser1}`}>
                  <tbody>
                    <tr>
                      <th className="bg-[#14141C]">year</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              className="bg-[#14141C] font-montserrat font-semibold"
                              key={i}
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>nifty</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i}>
                              {ele[1]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>nifty 500</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i}>
                              {ele[7]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>alpha 500</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[8]) > parseFloat(ele[7])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i}
                            >
                              {ele[8]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
                <table className={`${styles.tableUser3} mt-8`}>
                  <tbody>
                    <tr>
                      <th className="bg-[#14141C] ">year</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              className="bg-[#14141C] font-montserrat font-semibold"
                              key={i + 10}
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>nifty</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i + 10}>
                              {ele[1]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>nifty 500</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i + 10}>
                              {ele[7]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>alpha 500</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[8]) > parseFloat(ele[7])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i + 10}
                            >
                              {ele[8]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlphaIndiaOptions;

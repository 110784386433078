import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Login from "./Login/login";
import Dashboard from "./Home/DashBoard";
import { connect, useDispatch } from "react-redux";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import SignIn from "./NormalUser/Login/Signin";

import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import UserDashboard from "./NormalUser/Home/HomeDashBoard";
import "bootstrap/dist/css/bootstrap.min.css";

import { QueryClientProvider, QueryClient } from "react-query";
import AlphaFifty from "./NormalUser/Home/alpha50";
import AlphaHundred from "./NormalUser/Home/alpha100";
import AlphaTwoHundred from "./NormalUser/Home/alpha200";
import AlphaFiveHundred from "./NormalUser/Home/alpha500";
import AlphaSP from "./NormalUser/Home/alphaSP";
import AlphaNasdaq from "./NormalUser/Home/alphaNadaq";
import AlphaIndiaOptions from "./NormalUser/Home/alphaOptions";

const queryClient = new QueryClient();

function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Switch>
          {/* <Route
            exact
            path="/"
            component={() => (user ? <AlphaFifty /> : <SignIn />)}
          /> */}
           <Route
            exact
            path="/"
            component={() => (user ? <AlphaHundred /> : <SignIn />)}
          />
          <Route
            exact
            path="/alpha-options"
            component={() => (user ? <AlphaIndiaOptions /> : <SignIn />)}
          />
          <Route
            exact
            path="/alpha-200"
            component={() => (user ? <AlphaTwoHundred /> : <SignIn />)}
          />
          <Route
            exact
            path="/alpha-500"
            component={() => (user ? <AlphaFiveHundred /> : <SignIn />)}
          />
          <Route
            exact
            path="/alpha-s&p"
            component={() => (user ? <AlphaSP /> : <SignIn />)}
          />
          <Route
            exact
            path="/alpha-nasdaq"
            component={() => (user ? <AlphaNasdaq /> : <SignIn />)}
          />
          <Route
            exact
            path="/comparison"
            component={() => (user ? <UserDashboard /> : <SignIn />)}
          />
          <Route
            exact
            path="/admin"
            // component={Dashboard}
            component={() =>
              props.isAuthenticated ? <Dashboard /> : <Login />
            }
          />
        </Switch>
      </QueryClientProvider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(App);

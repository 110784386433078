import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Navbar from "../Navbar/Navbar";
import styles from "../../Home/HomePage.module.css";
import TableComponent from "./TableComponent";
import { auth } from "../../firebase";
import {
  CurrentUserData,
  UseTradeHistoryData,
  UseOverviewData,
  UseOverviewDataHeading,
  UseYearOnYearData,
  UseAlphaSPData,
} from "../../utils/data";
import DashBoard from "../../Home/DashBoard";
import OutPerformaceAndChart from "./OutPerformaceAndChartt";

const AlphaSP = () => {
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const { data: userHero } = CurrentUserData(userEmail);
  const userDetails = userHero && userHero.data.User;

  const { data: tradeHistory } = UseTradeHistoryData();

  const { data: overviewData } = UseOverviewData();
  const { data: overviewDataHeading } = UseOverviewDataHeading();
  const { data: yearOnYearData } = UseYearOnYearData();

  const newOverViewDataHeading =
    overviewDataHeading && overviewDataHeading.data.values;
  const newOverViewData = overviewData && overviewData.data.values;
  const newYearOnYearData = yearOnYearData && yearOnYearData.data.values;

  const newData1 = tradeHistory && tradeHistory.data.mappings;
  const result =
    newData1 &&
    newData1.filter(
      (item) => item.frontend_table_name === "Alpha S&P (20 Selections)"
    );
  const ssSpDataTablename = result && result[0]["table_name"];

  const ssSpData = tradeHistory && tradeHistory.data.data[ssSpDataTablename];

  // chart  data

  const { data: alphaSPData } = UseAlphaSPData();

  // alpha s&p start

  const alphaSPNewData = alphaSPData && alphaSPData.data.values;
  const alphaSPPerformceAccountValue =
    alphaSPNewData && alphaSPNewData.map((item) => [item[0], item[1], item[2]]);

  const alphaSPOutPerformance =
    alphaSPNewData && alphaSPNewData.map((item) => [item[10]]);
  const alphaSPOutPerformanceValue =
    alphaSPOutPerformance &&
    alphaSPOutPerformance.flat().filter((value) => value !== undefined && value !== "");

  const alphaSPPerformceAccountValueData =
    alphaSPPerformceAccountValue &&
    alphaSPPerformceAccountValue
      .filter(
        (innerArray) =>
          innerArray &&
          innerArray.every((value) => value !== undefined && value !== "")
      )
      .map((innerArray, index) =>
        innerArray.map((value, columnIndex) => {
          // Skip the first column
          if (columnIndex === 0) {
            return value;
          }

          // Remove the leading dollar sign and commas, then convert to a number
          return columnIndex === 1 || columnIndex === 2
            ? parseFloat(value.replace(/^\$|,/g, ""))
            : value;
        })
      );

  const newSPData =
    alphaSPNewData && alphaSPNewData.map((item) => [item[0], item[5], item[6]]);
  const dataArray = newSPData ? newSPData : [];

  const alphaSPMonthlyReturns = [dataArray[0]]; // Add the header to the new array

  // Create a Set to keep track of unique months
  const uniqueMonthsSet = new Set();

  // Iterate through the original array starting from index 1 (skipping the header)
  for (let i = 1; i < dataArray.length; i++) {
    const currentRow = dataArray[i];
    const currentDate = currentRow[0];

    // Check if currentDate is defined and is a valid string
    if (currentDate && typeof currentDate === "string") {
      // Convert the date string to a JS Date object
      const [day, month, year] = currentDate.split("/").map(Number);
      const currentMonthKey = `${month}/${year}`; // Extract the month and year part

      // Check if the current row has valid data (non-empty and non-undefined values)
      const hasValidData = currentRow
        .slice(1)
        .every((value) => value !== undefined && value !== "");

      // Only push to alphaSPMonthlyReturns if the row contains valid data and hasn't been added yet
      if (!uniqueMonthsSet.has(currentMonthKey) && hasValidData) {
        alphaSPMonthlyReturns.push(currentRow);
        uniqueMonthsSet.add(currentMonthKey);
      }
    }
  }

  const alphaCumulative = alphaSPNewData && alphaSPNewData[1][9];
  const niftyCumulative = alphaSPNewData && alphaSPNewData[5][9];

  const [newAlphaSPXIndexdata, setNewAlphaSPXIndexData] = useState([]);

  // Function to fetch data from the API
  const fetchAlphaSPXIndexData = async () => {
    try {
      const response = await fetch(
        "https://server.sypto.xyz/sypto/api/get_alpha_index_data"
      );
      const data = await response.json();

      // Format the data: extract only [value, label]
      const formattedData = (data.Alpha_SPX || []).map((item) => [
        item.value,
        item.label,
        item.allocation
      ]);

      // Update the state with the formatted data
      setNewAlphaSPXIndexData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // UseEffect to fetch data when component mounts
  useEffect(() => {
    fetchAlphaSPXIndexData();
  }, []);

  return (
    <div>
      {userDetails && userDetails.account_access === "admin" ? (
        <DashBoard />
      ) : (
        <div>
          <Navbar />

          <div className="mt-20">
            <div className="bg-[#14141C] flex flex-row py-8 px-12">
              <div className="px-8">
                <div className="text-2xl text-white font-montserrat">
                  Alpha - S&P
                </div>
                <div className="text-lg text-[#ffffff]/60 mt-2 font-poppins">
                  Unleashing the Power of Momentum Investing
                </div>
              </div>
              <div className="border-l-[1px] border-[#ffffff]/30 px-8">
                <div className="text-lg text-white font-montserrat font-semibold">
                  Investment Objective
                </div>
                <div className="text-base text-[#ffffff]/60 mt-2 font-poppins">
                  Alpha - S&P is a concentrated momentum investment portfolio
                  with weekly rebalancing. Our quantitative strategy scans the
                  top 500 stocks in the S&P500 index and selects a portfolio of
                  10 promising stocks for your investment.
                </div>
              </div>
            </div>
            <div className={`px-4 pt-4 pb-16 flex flex-row justify-between`}>
              <div className="w-[30%] pl-4">
                {/* {ssSpData && ssSpData ? (
                  <TableComponent watchlist={ssSpData && ssSpData} />
                ) : null} */}

                {newAlphaSPXIndexdata && newAlphaSPXIndexdata ? (
                  <TableComponent
                    watchlist={newAlphaSPXIndexdata && newAlphaSPXIndexdata}
                  />
                ) : null}

                <div className="w-full">
                  <div className="font-montserrat border-[1px] border-b-0 border-[#ffffff]/20 bg-[#14141C]  w-[90%] text-white mt-3 py-[10px] pl-3 font-semibold">
                    Statistics
                  </div>

                  <table className={`${styles.tableUser2}`}>
                    <thead>
                      {newOverViewDataHeading &&
                        newOverViewDataHeading.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <th scope="col"></th>

                              <th scope="col">{ele[9]}</th>
                              <th scope="col">{ele[10]}</th>
                            </tr>
                          );
                        })}
                    </thead>

                    <tbody>
                      {newOverViewData &&
                        newOverViewData.map((ele, i) => {
                          return (
                            <tr key={i}>
                              <td>{ele[0]}</td>
                              <td className="text-right">{ele[9]}</td>
                              <td className="text-right">{ele[10]}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-[70%]">
                <OutPerformaceAndChart
                  monthlyReturns={
                    alphaSPMonthlyReturns && alphaSPMonthlyReturns
                  }
                  performaceData={
                    alphaSPPerformceAccountValueData &&
                    alphaSPPerformceAccountValueData
                  }
                  outPerformance={alphaSPOutPerformanceValue}
                  titleOne="Alpha S&P"
                  titleTwo="S&P 500"
                  tabName="alpha-s&p"
                  alphaCumulative={alphaCumulative}
                  niftyCumulative={niftyCumulative}
                />
                <div className="w-full text-white text-2xl  py-4 pl-3 font-semibold font-montserrat">
                  Backtested Year-on-Year Performance
                </div>

                <table className={`${styles.tableUser1}`}>
                  <tbody>
                    <tr>
                      <th className="bg-[#14141C]">year</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              className="bg-[#14141C] font-montserrat font-semibold"
                              key={i}
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>s&p 500</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i}>
                              {ele[10]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>alpha - s&p</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(0, 10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[11]) > parseFloat(ele[10])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i}
                            >
                              {ele[11]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
                <table className={`${styles.tableUser3} mt-8`}>
                  <tbody>
                    <tr>
                      <th className="bg-[#14141C]">year</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              className="font-montserrat font-semibold bg-[#14141C]"
                              key={i + 10}
                            >
                              {ele[0]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>s&p 500</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td className="text-right" key={i + 10}>
                              {ele[10]}
                            </td>
                          );
                        })}
                    </tr>
                    <tr>
                      <th>alpha - s&p</th>
                      {newYearOnYearData &&
                        newYearOnYearData.slice(10).map((ele, i) => {
                          return (
                            <td
                              className={
                                parseFloat(ele[11]) > parseFloat(ele[10])
                                  ? styles.greenColor
                                  : styles.redColor
                              }
                              key={i + 10}
                            >
                              {ele[11]}
                            </td>
                          );
                        })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlphaSP;
